@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    20% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}

.save-pop-up-class {
    animation: fadeIn 10s;
    transition: ease-in-out;
}

.follow-launch {
    cursor: pointer;
    color: rgb(78, 78, 255);
}
