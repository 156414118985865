.tool-bar-icon {
  clip-path: polygon(
    0% 0px,                  /* top left */
    0px 0%,                  /* top left */
    calc(100% - 16px) 0%,    /* top right */
    100% 16px,               /* top right */
    100% calc(100% - 0px),   /* bottom right */
    calc(100% - 0px) 100%,   /* bottom right */
    0px 100%,                /* bottom left */
    0 calc(100% - 0px)       /* bottom left */
  );
}


.toolbar-scroll-container::-webkit-scrollbar {
  opacity: 0;
  width: .7rem;
  height: 10px;
  right: 1rem;
}

.toolbar-scroll-container::-webkit-scrollbar:hover {
  opacity: 1;
}

.toolbar-scroll-container::-webkit-scrollbar-track {
  border-radius: 4px;
  background-color: transparent;
}

.toolbar-scroll-container::-webkit-scrollbar-thumb {
  background: rgb(7, 90, 146);
  border-radius: 20px;
  cursor: pointer;
}

.toolbar-scroll-container::-webkit-scrollbar-corner {
  background-color: transparent;
}

.tool-bar-container{
  position: relative;
  display: inline-block;
}

.tool-bar-menu{
  display: none;
  position: absolute;
  background-color: transparent;
  top: -8px;
  right: -8px;
  z-index: 1;
  transition: all 0.3s ease;
  cursor: pointer;
}


.tool-bar-container:hover .tool-bar-menu{
  display: block;
}

.toolbar-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 0 .25rem;
}

.toolbar-scroll-container {
  align-items: center;
  padding: 0 1rem 0 0;
  position: relative;
  margin-bottom: .5rem;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
}

.tool-tip-container {
  position: absolute;
  top: -1.5rem;
  left: 0;
  right: 0;
  visibility: hidden;
  padding-left: 5px;
  opacity: .7;
}

.toolbar-plus-container:hover .tool-tip-container {
  visibility: visible;
}

/* Tooltip css */
.tool-bar-tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}


.edit-meta-icon-tooltip {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.edit-meta-icon-tooltip::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.edit-meta-icon-container:hover .edit-meta-icon-tooltip {
  visibility: visible;
  opacity: 1;
}
