.teams-edit-icon-helper{
  visibility: hidden;
  position: absolute;
  top: -2rem;
  right: 0;
  left: 0;
  margin: auto;
  width: 5rem;
}

.teams-edit-icon{
  position: relative;
}

.teams-edit-icon:hover .teams-edit-icon-helper{
  visibility: visible;
}