.jsoncrack section {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
}
.jsoncrack textarea {
    width: 100%;
    height: 100%;
}

#jsoncrackEmbed {
    flex: 1;
    order: 2;
    width: 100%;
    height: 100%;
    border: none;
}

#json-crack-editor-id {
    width: 100%;
    height:100%;
}

@keyframes blink-on-load-light {
    to {
        background-color: #e2e8f0;
    }
}

@keyframes blink-on-load-dark {
    to {
        background-color: #171923;
    }
}
.json-crack-light-editor {
    animation-name: blink-on-load-light;
    animation-timing-function: linear;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}

.json-crack-dark-editor {
    animation-name: blink-on-load-dark;
    animation-timing-function: linear;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}
