@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Source+Code+Pro:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
/* @import url("https://cdn.jsdelivr.net/npm/xterm@5.1.0/css/xterm.min.css"); */

:root {
  --ext-container-color: #a4b0ef;
  --container-color: black;
  --text-color: #fff;
  --ps1-color: #8817cc;
  --x-container-color: rgb(255, 255, 255);
}

.terminal-ps1 {
  color: var(--ps1-color);
  font-weight: 500;
}

.terminal-ext {
  margin-bottom: 1rem;
  width: 100%;
  height: 45%;
  border-radius: 1rem;
  padding: 1rem 1rem;
  font-family: 'Source Code Pro', monospace;
}

.code-container {
  width: 100%;
  overflow-y: auto;
  font-family: 'Source Code Pro', monospace;
  font-size: 16px;
  padding: 0 1rem;
  height: 100%;
  position: relative;
}

.terminal-ext a {
  text-decoration: underline;
}

.xterm-viewport {
  width: 100% !important;
  overflow-y: auto !important;
}

.xterm {
  user-select: all ;
}

.xterm-bold {
  font-weight: 900;
  color: #000;
}