.xterm-viewport::-webkit-scrollbar {
    opacity: 0;
    width: .7rem;
    right: 1rem;
}

.xterm-viewport::-webkit-scrollbar:hover {
    opacity: 1;
}

.xterm-viewport::-webkit-scrollbar-track {
    border-radius: 4px;
    background-color: transparent;
}

.xterm-viewport::-webkit-scrollbar-thumb {
    background: rgb(7, 90, 146);
    border-radius: 20px;
    visibility: hidden;
    cursor: pointer;
}

.terminal:hover .xterm-viewport::-webkit-scrollbar-thumb {
    visibility: visible;
}

.xterm-viewport::-webkit-scrollbar-corner {
    background-color: transparent;
}
