/* Terminal color configuration */

.terminal [type~="color-black"] {
  color: black !important;
}

.terminal [type~="color-white"] {
  color: white !important;
}

.terminal [type~="color-red"] {
  color: red !important;
}

.terminal [type~="color-green"] {
  color: green !important;
}

.terminal [type~="color-blue"] {
  color: blue !important;
}

.terminal [type~="color-yellow"] {
  color: yellow !important;
}

.terminal [type~="color-cyan"] {
  color: cyan !important;
}

.terminal [type~="color-magenta"] {
  color: magenta !important;
}

.terminal [type~="color-BrightBlack"] {
  color: #555 !important;
}

.terminal [type~="color-BrightRed"] {
  color: #f00 !important;
}

.terminal [type~="color-BrightGreen"] {
  color: #0f0 !important;
}

.terminal [type~="color-BrightYellow"] {
  color: #ff0 !important;
}

.terminal [type~="color-BrightBlue"] {
  color: #00f !important;
}

.terminal [type~="color-BrightMagenta"] {
  color: #f0f !important;
}

.terminal [type~="color-BrightCyan"] {
  color: #0ff !important;
}

.terminal [type~="load-indicator"] {
  color: #0723d6 !important;
}

.terminal [type~="color-default"] {
  color: blue !important;
}

.terminal [type~="quotes"] {
  color: green !important;
}

.terminal [type~="number"] {
  color: rgb(13, 146, 156);
}

/* indent curley brackets */
.terminal [type~="curley"] {
  padding-left: 2rem;
  /* background-color: #f00; */
}

