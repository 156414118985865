@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");
body {
  font-family: "DM Sans", sans-serif;
}

option {
  color: black;
}

.dock {
  margin: 1rem 0.5rem;
  padding-right: 0.5rem;
  height: 518px;
  border-right: 1px solid black;
  overflow-y: auto;
  width: 275px;
  padding-left: 0.5rem;
  overflow-x: hidden;
}

.dock .node {
  margin: 8px auto;
}
.dock .node:nth-child(1) {
  margin-top: 0;
}
.dock input {
  width: 100px;
  font-size: 14px;
  border-radius: 5px;
  padding-left: 5px;
}
.dock .control {
  padding: 0;
}
.dock .output {
  padding: 0;
}
.dock .output-title {
  margin: 0;
  font-size: 14px;
  padding: 0;
}
.dock .node .title {
  font-size: 14px;
  padding: 5px;
  margin-left: 10px;
}
