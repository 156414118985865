@keyframes blink-background-light {
    to {
        background-color: #E2E8F0;
    }
}

@keyframes blink-background-dark {
    to {
        background-color: #171923;
    }
}

.data-table-dark-bg{
    animation-name: blink-background-dark;
    animation-timing-function: linear;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    background-clip: content-box;
    height: 3rem
}

.data-table-light-bg{
    animation-name: blink-background-light;
    animation-timing-function: linear;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    background-clip: content-box;
    height: 3rem
}